import type { IDekeyConfig } from 'dekey-v2-wallet-core';

export const REALM = 'fortress';
export const GAME_SERVICE_BASE_URL = import.meta.env.VITE_GAME_SERVICE_BASE_URL;
export const FEE_RECIPIENT = import.meta.env.VITE_FEE_RECIPIENT;
export const IMAGE_CACHE_SERVICE = 'https://fortress-arena.s3.ap-northeast-2.amazonaws.com';
export const DEV_ENV = true;
export const NETWORK_MAINNET = 'mainnet';
export const NETWORK_POLYGON = 'polygon';
const PROD_MAINNET_CONFIG = {
	TANK_NFT: import.meta.env.VITE_MAINNET_TANK_NFT,
	SEAPORT: import.meta.env.VITE_MAINNET_SEAPORT,
	// WETH ERC20
	ERC20: import.meta.env.VITE_MAINNET_ERC20,

	// const hardhatChainID '0x7A69';
	// const ganacheChainID '0x539';
	// const goerliChainID '0x5';
	CHAIN_ID_HEX: '0x1',
	RPC_URL: 'https://mainnet.infura.io/v3/',
	TX_BLOCK_EXPLORER: 'https://etherscan.io/tx',
	ADD_BLCOK_EXPLORER: 'https://etherscan.io/address'
};
const DEV_MAINNET_CONFIG = {
	TANK_NFT: import.meta.env.VITE_MAINNET_TANK_NFT,
	SEAPORT: import.meta.env.VITE_MAINNET_SEAPORT,
	// WETH ERC20
	ERC20: import.meta.env.VITE_MAINNET_ERC20,

	// const hardhatChainID '0x7A69';
	// const ganacheChainID '0x539';
	// const goerliChainID '0x5';
	CHAIN_ID_HEX: '0xaa36a7',
	RPC_URL: 'https://mainnet.infura.io/v3/',
	TX_BLOCK_EXPLORER: 'https://sepolia.etherscan.io/tx',
	ADD_BLCOK_EXPLORER: 'https://sepolia.etherscan.io/address'
};
const PROD_POLYGON_CONFIG = {
	TANK_NFT: import.meta.env.VITE_POLYGON_TANK_NFT,
	SEAPORT: import.meta.env.VITE_POLYGON_SEAPORT,
	// WMATIC ERC20
	ERC20: import.meta.env.VITE_POLYGON_ERC20,
	CHAIN_ID_HEX: '0x89',
	RPC_URL: 'https://polygon-mainnet.infura.io',
	TX_BLOCK_EXPLORER: 'https://polygonscan.com/tx',
	ADD_BLCOK_EXPLORER: 'https://polygonscan.com/address'
};

const DEV_POLYGON_CONFIG = {
	TANK_NFT: import.meta.env.VITE_POLYGON_TANK_NFT,
	SEAPORT: import.meta.env.VITE_POLYGON_SEAPORT,
	// WMATIC ERC20
	ERC20: import.meta.env.VITE_POLYGON_ERC20,
	CHAIN_ID_HEX: '0x13881',
	RPC_URL: 'https://polygon-mumbai.blockpi.network/v1/rpc/public',
	TX_BLOCK_EXPLORER: 'https://mumbai.polygonscan.com/tx',
	ADD_BLCOK_EXPLORER: 'https://mumbai.polygonscan.com/address'
};

export const CONTRACT_ADDRESS: {
	[key: string]: {
		TANK_NFT: string;
		SEAPORT: string;
		ERC20: string;
		CHAIN_ID_HEX: string;
		RPC_URL: string;
		TX_BLOCK_EXPLORER: string;
		ADD_BLCOK_EXPLORER: string;
	};
} = {
	mainnet: import.meta.env.VITE_IS_PROD === "false" ? DEV_MAINNET_CONFIG : PROD_MAINNET_CONFIG,
	polygon: import.meta.env.VITE_IS_PROD === "false" ? DEV_POLYGON_CONFIG : PROD_POLYGON_CONFIG
};
export const LINK_EXTERNAL_WALLET_MODEL_REMINDER_KEY = 'linkExternalWalletModelReminder';

// NOTE) Put mpc_worker.mjs somewhere when you deploy the app.
// 'workerFileBasePath' should point that location.

// IMPORTANT) wasm_exec.js and dkeyswasm.wasm should be placed in the same location where mpc_worker.mjs is in
const workerFileBasePath = `${import.meta.env.VITE_OAUTH_REDIRECT_HOST}/dekey-v2-wallet-core/dist/js/`;

export const dekeyConfig: IDekeyConfig = {
	aws: {
		kmsKeyId: import.meta.env.VITE_AWS_KMS_KEY_ID,
		region: import.meta.env.VITE_AWS_REGION,
		identityPoolId: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
		userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
		tokenProvider: import.meta.env.VITE_AWS_TOKEN_PROVIDER,
		credentialProvider: import.meta.env.VITE_AWS_CREDENTIAL_PROVIDER,
		roleArn: import.meta.env.VITE_AWS_ROLE_ARN, // OPTIONAL
		cognitoSyncDataset: import.meta.env.VITE_AWS_COGNITO_SYNC_DATASET
	},
	aesSource: '',
	workerFileBasePath,
	appServer: {
		appServerAddressProtocol: import.meta.env.VITE_APP_SERVER_ADDRESS_PROTOCOL,
		appServerAddress: import.meta.env.VITE_APP_SERVER_ADDRESS
	},
	mpc: {
		enableMPCTLS: import.meta.env.VITE_APP_MPC_TLS
	}
};
