import type { TransactionError } from '$lib/types';
import { get } from 'svelte/store';

import { chainId } from '$lib/stores/connectstore';
import { CONTRACT_ADDRESS } from '../../constants';

export function isTransactionError(error: unknown): error is TransactionError {
	return typeof error === 'object' && error !== null && 'transaction' in error;
}

export function toErrorMessage(error: unknown): string {
	if (isTransactionError(error)) {
		return error?.error?.data?.originalError?.message;
	}
	return 'Application has encountered an error. Please try after sometime.';
}

export async function validateAccountHandle(network: string) {
	if (
		!!window.ethereum &&
		`${get(chainId)}` !== CONTRACT_ADDRESS[network].CHAIN_ID_HEX &&
		window.ethereum.request
	) {
		try {
			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: CONTRACT_ADDRESS[network].CHAIN_ID_HEX }]
			});
		} catch (error: any) {
			// This error code indicates that the chain has not been added to MetaMask
			// if it is not, then install it into the user MetaMask
			if (error.code === 4902) {
				try {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [
							{
								chainId: CONTRACT_ADDRESS[network].CHAIN_ID_HEX,
								rpcUrl: CONTRACT_ADDRESS[network].RPC_URL
							}
						]
					});
				} catch (addError) {
					console.error(addError);
				}
			}
			console.error(error);
		}
	}

	// TODO check with attached wallet
	// if (!!window.ethereum && window.ethereum.request) {
	// 	const w = get(activeWallet);
	// 	if (!!w) {
	// 		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
	// 		if (accounts[0] != w.address) {
	// 			notifications.danger(
	// 				`Switch to ${genShortAddress(w.address)} active attached wallet.`,
	// 				7000
	// 			);
	// 			throw new Error(`Switch to wallet account ${w.address}`);
	// 		}
	// 	}
	// }
}
