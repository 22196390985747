import { browser } from '$app/environment';
import type { GoogleSession, Account, GoogleAccessToken, KeycloakAuth, Wallet } from '$lib/types';
import { writable } from 'svelte/store';

export const userInfo = writable<Account | undefined>(undefined);

export const keycloak = writable<KeycloakAuth | undefined>(undefined);

export const activeWallet = writable<Wallet | undefined>(undefined);
export const activeGameWallet = writable<Wallet | undefined>(undefined);

export const authenticatedUsingEmail = writable<boolean>(false);

export const session = writable<GoogleSession | undefined>({
    expired: false
});

export function logoutSession() {
    googleSession.set(undefined);
    userInfo.set(undefined);
    keycloak.set(undefined);
    activeWallet.set(undefined);
    activeGameWallet.set(undefined);
    authenticatedUsingEmail.set(false);
}

export const sessionExpired = () => {
    logoutSession();
    session.set({
        expired: true
    })
};


const defaultGoogleSession = undefined;

export function getGoogleSession() {
    if (browser) {
        const googleSessionJSON = window.localStorage.getItem('google_access_token');
        if (googleSessionJSON) {
            return JSON.parse(googleSessionJSON) as GoogleAccessToken;
        }
    }
    return defaultGoogleSession
}
export const googleSession = writable<GoogleAccessToken | undefined>(getGoogleSession());
googleSession.subscribe((value) => {
    if (browser) {
        if (typeof value === 'undefined') {
            window.localStorage.removeItem('google_access_token');
        } else {
            window.localStorage.setItem('google_access_token', JSON.stringify(value));
        }
    }
});
