import { getGoogleSession, googleSession, keycloak, sessionExpired } from '$lib/stores/userstore';
import type { GoogleAccessToken, KeycloakAuth, KeycloakExchangeToken } from '$lib/types';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export async function getGoogleAccessToken(code: string) {
	const api = axios.create({});
	const tokenResponse = await api.get<GoogleAccessToken>(
		'/markets/google/codeCallback',
		{
			params: {
				code: code
			}
		}
	);
	return tokenResponse;
}

export async function getGoogleAccessTokenFromRefreshToken(oldToken: GoogleAccessToken) {
	const api = axios.create({});
	const tokenResponse = await api.post<void, AxiosResponse<GoogleAccessToken>>(
		'/markets/google/refreshToken',
		oldToken
	);
	return tokenResponse;
}

export async function revokeGoogleAccessToken(oldToken: GoogleAccessToken | undefined) {
	if (oldToken) {
		const api = axios.create({});
		const tokenResponse = await api.post<void, AxiosResponse<GoogleAccessToken>>(
			'/markets/google/logout',
			oldToken
		);
		return tokenResponse;
	}
}

export async function getGoogleTokenExchanged(_session: GoogleAccessToken) {
	const api = axios.create({});
	
	const refreshAuthLogic = (failedRequest) => {
		return new Promise<void>((resolve, reject) => {
			getGoogleAccessTokenFromRefreshToken(_session).then(
				(response) => {
					if (response && response.data.access_token.length > 0) {
						googleSession.set(response.data);
						resolve();
					} else {
						reject(response);
					}
				},
				(response) => {
					reject(response);
				}
			);
		})
	};

	createAuthRefreshInterceptor(api, refreshAuthLogic);
	const tokenResponse = await api.post<void, AxiosResponse<KeycloakExchangeToken>>(
		'/markets/v2/exchangeToken',
		{
			jwt_token: _session.access_token
		}
	);
	return tokenResponse;
}

export async function refreshAccessTokenExchanged(previousToken: KeycloakExchangeToken) {
	console.log("refreshing keycloak token");
	const api = axios.create({});
	try {
		const tokenResponse = await api.post<void, AxiosResponse<KeycloakExchangeToken>>(
			'/markets/refreshToken',
			previousToken
		);
		return tokenResponse;
	} catch (error) {
		if (axios.isAxiosError(error) && error?.response?.status === 401) {
			console.log("refreshing Google access token, statuscode", error?.response?.status);
			const oldAccessToken = getGoogleSession();
			const newAccessTokenResp = await getGoogleAccessTokenFromRefreshToken(oldAccessToken!);
			googleSession.set(newAccessTokenResp.data);
			const keycloakResp = getGoogleTokenExchanged(newAccessTokenResp.data)
			return keycloakResp;
		}
	}
}

export function isAxiosResponse(error: unknown): error is AxiosError {
	return typeof error === 'object' && error !== null && 'response' in error;
}

export function getDefaultAxiosWithToken(_keycloak: KeycloakAuth) {
	// refreshToken
	const api = axios.create({
		headers: { Authorization: `Bearer ${_keycloak.token}` }
	});

	const refreshAuthLogic = (failedRequest) => {
		return new Promise<void>((resolve, reject) => {
			refreshAccessTokenExchanged({
				access_token: _keycloak.token,
				refresh_token: _keycloak.refreshToken,
			}).then(
				(response) => {
					if (response && response.data.access_token.length > 0) {
						_keycloak.token = response.data.access_token;
						_keycloak.refreshToken = response.data.refresh_token;
						keycloak.set(_keycloak);
						failedRequest.response.config.headers['Authorization'] = 'Bearer ' + _keycloak.token;
						resolve();
					} else {
						reject(response);
					}
				},
				(response) => {
					reject(response);
				}
			);
		})
	};

	createAuthRefreshInterceptor(api, refreshAuthLogic);

	api.interceptors.response.use(
		(response) => response,
		(response) => {
			if (response.response.status === 401) {
				sessionExpired()
			}
			return Promise.reject(response);
		}
	);
	return api;
}

export function getDefaultAxios() {
	const api = axios.create({});
	return api;
}
