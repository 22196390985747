import { browser } from '$app/environment';
import type { CustomWeb3Provider } from '$lib/types';
import { writable } from 'svelte/store';

const rememberMeInitialVal = browser ? localStorage.getItem('rememberMe') ?? 'false' : 'false';
export const rememberMe = writable(rememberMeInitialVal);
rememberMe.subscribe((val) => {
	if (browser) {
		localStorage.setItem('rememberMe', val);
	}
});

export const showWalletProviders = writable(false);
export const web3Provider = writable<CustomWeb3Provider>();

export const selectedAccount = writable<string | undefined>();
export const connected = writable<boolean | undefined>();
export const chainName = writable<string | undefined>();
export const chainId = writable<string | undefined>();

export const refreshWebConnectionInfo = (provider: CustomWeb3Provider) => {
	if (!!provider.selectedAddress) {
		web3Provider.set(provider);
		connected.set(true);
		selectedAccount.set(provider.selectedAddress);
	}
};
